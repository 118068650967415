<!--
 * @Author: chenlei
 * @Date: 2020-11-24 10:51:36
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-27 17:13:47
 * @Description:
-->
<script>
import TablePage from '../../../../../../components/table_page';
import configs from './data';
import Modal from '../../../../../../components/modal';
import request from '../../../../../../utils/request';
import FieldForm from '../form';

export default {
  extends: TablePage,
  components: {
    Modal,
    FieldForm,
  },
  props: {
    code: String,
  },
  data() {
    return {
      requestUrl: '',
      params: {
        dictTypeCode: '',
      },
      configs,
      formConfig: {},
      selectRow: [],
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  mounted() {
    console.log('this.row>>>', this.code);
  },
  watch: {
    code(n) {
      console.log('n>>>', n);
      this.requestUrl = '/mdm/mdmdictattrconf/findAttrConfList';
      this.params = {
        dictTypeCode: n,
      };
      this.getList();
    },
  },
  methods: {
    modalClick({ val, row }) {
      this.formName = 'FieldForm';
      const btnCode = val.code;
      const { dictTypeCode } = this.params;
      const selectRow = typeof this.selectRow !== 'undefined' && this.selectRow.length !== 0 ? this.selectRow[0] : row;
      let isOpenModal = true;
      if (btnCode === 'add-extend') {
        this.modalConfig.title = '新增';
        this.formConfig.dictTypeCode = dictTypeCode;
      } else if (btnCode === 'edit-extend') {
        if (selectRow) {
          this.modalConfig.title = '编辑';
          this.formConfig = {
            ...val,
            selectRow,
            dictTypeCode,
          };
        } else {
          isOpenModal = false;
          this.$message({
            type: 'info',
            message: '请选择一条数据！',
          });
        }
      } else if (btnCode === 'delete-extend') {
        isOpenModal = false;
        this.deleteClick(selectRow);
      }

      if (isOpenModal) {
        this.openModal();
      }
    },
    deleteClick(row) {
      let ids = [];
      if (row) {
        ids = [row.id];
      }
      if (ids && ids.length > 0) {
        this.$confirm('是否删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request.post('/mdm/mdmdictattrconf/delete', ids)
              .then((res) => {
                if (res.success) {
                  this.$message({
                    type: 'success',
                    message: '删除成功',
                  });
                  this.getList();
                }
              });
          });
      }
    },
  },
};
</script>
