import TablePage from '../../../../../components/table_page';
import configs from './configs';
import DictType from '../dict_type';
import Modal from '../../../../../components/modal';
import FieldExtension from '../field_extension';
import DictDetail from '../dict_detail';
import request from '../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Modal,
    DictType,
    FieldExtension,
    DictDetail,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmdicttype/list',
      configs,
      formConfig: {
        dictTypeList: [],
      },

      selectRow: [],
      modalConfig: {
        title: '新增',
        visible: false,
      },
    };
  },
  created() {},
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'DictType';
      const btnCode = val.code;
      const selectRow = typeof this.selectRow !== 'undefined' && this.selectRow.length !== 0 ? this.selectRow[0] : null;
      let isOpenModal = true;
      if (btnCode === 'add') {
        this.modalConfig.title = '新增';
      } else if (btnCode === 'edit') {
        if (selectRow) {
          this.modalConfig.title = '编辑';
          this.formConfig = {
            ...val,
            selectRow,
          };
        } else {
          isOpenModal = false;
          this.$message({
            type: 'info',
            message: '请选择一条数据！',
          });
        }
      } else if (btnCode === 'props-setting') {
        if (selectRow) {
          this.modalConfig.title = '属性设置';
          this.formName = 'FieldExtension';
          this.formConfig = {
            ...val,
            selectRow,
          };
        } else {
          isOpenModal = false;
          this.$message({
            type: 'info',
            message: '请选择一条数据！',
          });
        }
      } else if (btnCode === 'add-dict') {
        this.modalConfig.title = '字典';
        this.formName = 'DictDetail';
        this.formConfig = {
          ...val,
          selectRow: row,
        };
      }

      if (isOpenModal) {
        this.openModal();
      }
    },
    deleteClick({ row }) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request.post('/mdm/mdmdicttype/delete', [row.id])
            .then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                });
                this.getList();
              }
            });
        })
        .catch(() => {
        });
    },
  },
};
