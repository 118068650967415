/*
 * @Author: chenlei
 * @Date: 2020-11-25 11:46:36
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-25 14:51:26
 * @Description:
 */
import FieldTable from './dict.table.vue';

export default FieldTable;
