/*
 * @Author: chenlei
 * @Date: 2020-11-25 11:46:36
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-25 13:42:41
 * @Description:
 */
import FieldTable from './field.table.vue';

export default FieldTable;
