<!--
 * @Author: chenlei
 * @Date: 2020-11-24 17:42:03
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-26 21:39:56
 * @Description:
-->
<script>
import Form, { formCreate } from '../../../../../components/form';
import FieldExtension from './table/table.vue';

formCreate.component('FieldExtension', FieldExtension);

export default {
  extends: Form,
  components: {
    FieldExtension,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: true,
      },
      rule: [
        {
          field: 'list',
          type: 'FieldExtension',
          props: {
            code: '',
          },
        },
      ],
    };
  },
  mounted() {
    this.updateRules({
      list: {
        props: {
          code: this.formConfig.selectRow ? this.formConfig.selectRow.dictTypeCode : '',
        },
      },
    });
  },
  methods: {
    // 提交
    submit() {

    },
  },
};
</script>
