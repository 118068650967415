export default {
  formConfig: {
    titleWidth: 100,
    titleAlign: 'right',
    item: [
      {
        field: 'dictTypeModule',
        search: true,
        title: '所属模块',
        type: 'select',
        apiUrl: '/mdm/mdmdictdata/list',
        optionsKey: {
          label: 'dictValue',
          value: 'dictCode',
        },
        paramsFixed: {
          dictTypeCode: 'module_group',
        },
      },
      {
        field: 'dictTypeCode',
        search: true,
        title: '类型编码',
        type: 'input',
      },
      {
        field: 'dictTypeName',
        search: true,
        title: '类型名称',
        type: 'input',
      },
    ],
  },
  toolBarConfig: [
    {
      type: 'button',
      bbuttonType: null,
      // icon: 'el-icon-plus',
      code: 'add',
      name: '添加类型',
    },
    {
      type: 'button',
      buttonType: null,
      // icon: 'el-icon-edit',
      code: 'edit',
      name: '修改类型',
    },
    {
      type: 'button',
      buttonType: null,
      // icon: 'el-icon-setting',
      name: '属性设置',
      code: 'props-setting',
    },
  ],
  columns: [
    {
      fixed: 'left', // 固定位置在左边
      type: 'checkbox', // 单选
      title: null,
      width: '50',
    },
    {
      field: 'dictTypeModuleName',
      title: '所属模块',
    },
    {
      field: 'dictTypeCode',
      title: '类型编码',
      search: true,
      type: 'input',
    },
    {
      field: 'dictTypeName',
      title: '类型名称',
      search: true,
      type: 'input',
    },
    {
      field: 'dictTypeDesc',
      title: '描述',
    },
    {
      field: 'createDate',
      title: '创建时间',
    },
    {
      field: 'createName',
      title: '创建人',
    },
    {
      field: 'updateDate',
      title: '最近修改时间',
    },
    {
      field: 'updateName',
      title: '最近修改人',
    },
    {
      title: '操作',
      width: '120',
      align: 'center',
      buttons: [
        {
          type: 'button',
          buttonType: 'primary',
          code: 'add-dict',
          name: '添加',
          props: {},
        },
        {
          type: 'dropdown',
          buttonType: 'link',
          code: 'delete',
          name: '删除',
          props: {},
        },
        // {
        //   type: 'dropdown',
        //   buttonType: 'link',
        //   code: 'journal',
        //   name: '日志',
        //   props: {},
        // },
      ],
    },
  ],
  tableConfig: {
    border: true,
    // treeConfig: {
    //   children: 'children',
    // },
    idKey: 'id', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
