import TablePage from '../../../../../../components/table_page';
import configs from './configs';
import Modal from '../../../../../../components/modal';
import DictForm from '../form';
import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Modal,
    DictForm,
  },
  props: {
    code: String,
  },
  data() {
    return {
      requestUrl: '',
      params: {},
      configs,
      formConfig: {},
      selectRow: [],
      isCalculateHeight: false,
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {},
  watch: {
    code(n) {
      this.requestUrl = '/mdm/mdmdictdata/treeList';
      this.params = {
        dictTypeCode: n,
      };
      this.getExtendField();
    },
  },
  methods: {
    modalClick({ val, row }) {
      this.formName = 'DictForm';

      const btnCode = val.code;
      const { dictTypeCode } = this.params;

      const isOpenModal = true;
      if (btnCode === 'add-dict') {
        this.modalConfig.title = '新增';
        this.formConfig.dictTypeCode = dictTypeCode;
        this.formConfig.row = {};
        this.formConfig.val = {};
      } else if (btnCode === 'add-dict-child') {
        this.modalConfig.title = '添加下级';
        this.formConfig = {
          ...val,
          parentDictCode: row.dictCode,
          dictTypeCode,
        };
      } else if (btnCode === 'edit-dict') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          ...val,
          row,
          dictTypeCode,
          parentDictCode: row.parentDictCode,
        };
      } else if (btnCode === 'del-extend') {
        console.log(row);
      }

      if (isOpenModal) {
        this.openModal();
      }
    },
    getExtendField() {
      const { dictTypeCode } = this.params;
      request
        .post('/mdm/mdmdictattrconf/findAttrConfList', {
          dictTypeCode,
        })
        .then((res) => {
          if (res.success) {
            const column = [];
            const data = res.result || [];
            data.forEach((item) => {
              column.push({
                field: item.extField,
                title: item.fieldName,
              });
            });
            const columns = this.configs.columns.filter((v) => v.old);
            this.configs.columns = columns.concat(column);
            this.configs.columns.push({
              fixed: 'right', // 固定位置在左边
              title: '操作',
              width: '120',
              align: 'center',
              buttons: [
                {
                  type: 'button',
                  buttonType: null,
                  icon: 'el-icon-edit',
                  code: 'add-dict-child',
                  name: '添加下级',
                },
                {
                  type: 'button',
                  buttonType: null,
                  icon: 'el-icon-edit',
                  code: 'edit-dict',
                  name: '编辑',
                },
              ],
            });
            const list = this.configs.columns;
            this.configs.columns = [];
            setTimeout(() => {
              this.configs.columns = list;
            }, 1);
            this.getList();
          }
        });
    },
    // 删除
    async deleteClick() {
      if (this.selectRow.length > 0) {
        const ids = await this.getIds(this.selectRow);
        this.$confirm('是否删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request.post('/mdm/mdmdictdata/delete', ids).then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                });
                this.getList();
              }
            });
          });
      } else {
        this.$message({
          type: 'info',
          message: '请选择一条数据！',
        });
      }
    },
    // 获取所有的id集合
    getIds(list) {
      const ids = list.map((v) => v.id);

      list.forEach(async (item) => {
        if (item.children && item.children.length > 0) {
          const subIds = await this.getIds(item.children);
          ids.push(...subIds);
        }
      });

      return ids;
    },
  },
};
