<!--
 * @Author: chenlei
 * @Date: 2020-11-24 17:42:03
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-27 17:12:19
 * @Description:
-->
<script>
import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [
        {
          type: 'input',
          field: 'fieldCode',
          title: '字段编码',
          value: this.formConfig.selectRow && this.formConfig.selectRow.fieldCode,
          props: {
            placeholder: '请输入字段编码',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请输入字段编码',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'fieldName',
          title: '字段名称',
          value: this.formConfig.selectRow && this.formConfig.selectRow.fieldName,
          props: {
            placeholder: '请输入字段名称',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请输入字段名称',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'selectDictTypeCode',
          title: '数据源字典',
          value: this.formConfig.selectRow && this.formConfig.selectRow.selectDictTypeCode,
          props: {
            placeholder: '请输入数据源字典',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'select',
          field: 'selectSingleOnly',
          title: '数据源仅单选',
          value: this.formConfig.selectRow && this.formConfig.selectRow.selectSingleOnly,
          dictCode: 'yes_or_no',
          props: {
            placeholder: '请选择',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'showOrder',
          title: '显示顺序',
          value: this.formConfig.selectRow && this.formConfig.selectRow.showOrder,
          props: {
            placeholder: '请输入显示顺序',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请输入显示顺序',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'select',
          field: 'required',
          title: '是否必填',
          value: this.formConfig.selectRow && this.formConfig.selectRow.required,
          props: {
            placeholder: '请选择是否必填',
            disabled: false,
            clearable: true, // 是否显示清空按钮
            options: [],
            loading: true,
          },
          validate: [
            {
              trigger: 'change',
              required: true,

              message: '请选择是否必填',
            },
          ],
          dictCode: 'yes_or_no',
          col: {
            md: { span: 12 },
          },
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();
      const { dictTypeCode, selectRow, code } = this.formConfig;

      if (formData) {
        let url = '/mdm/mdmdictattrconf/save';
        let params = formData;

        params.dictTypeCode = dictTypeCode;

        if (code === 'edit-extend') {
          url = '/mdm/mdmdictattrconf/update';
          params = {
            ...formData,
            id: selectRow.id,
            extField: selectRow.extField,
          };
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            // this.closeModal();
            // this.getList();
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
    init() {
      const { selectRow } = this.formConfig;
      if (!selectRow || !selectRow.id) {
        return false;
      }
      request.get('/mdm/mdmdictattrconf/query', { id: selectRow.id }).then((res) => {
        if (res.success) {
          const { result } = res;
          this.setValue(result);
        }
      });
    },
  },
};
</script>
