/*
 * @Author: chenlei
 * @Date: 2020-11-24 17:01:07
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-27 17:11:31
 * @Description:
 */
export default {
  toolBarConfig: [
    {
      type: 'button',
      buttonType: 'primary',
      icon: 'el-icon-plus',
      code: 'add-extend',
      name: '新增',
    },
    {
      type: 'button',
      buttonType: 'primary',
      icon: 'el-icon-edit',
      code: 'edit-extend',
      name: '编辑',
    },
  ],
  columns: [
    {
      fixed: 'left', // 固定位置在左边
      type: 'checkbox', // 单选
      title: null,
      width: '50',
    },
    {
      field: 'dictTypeCode',
      title: '类型编码',
    },
    {
      field: 'fieldCode',
      title: '字段编码',
    },
    {
      field: 'fieldName',
      title: '字段名称',
    },
    {
      field: 'selectDictTypeCode',
      title: '数据源字典',
    },
    {
      field: 'showOrder',
      title: '显示顺序',
    },
    {
      field: 'requiredName',
      title: '是否必填',
    },
    {
      title: '操作',
      width: '120',
      align: 'center',
      buttons: [
        {
          type: 'dropdown',
          code: 'delete-extend',
          name: '删除',
          props: {},
        },
      ],
    },
  ],
  tableConfig: {
    border: true,
    // treeConfig: {
    //   children: 'children',
    // },
    // idKey: 'id', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
