<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [
        {
          type: 'select',
          field: 'dictTypeModule',
          title: '所属模块',
          value: this.formConfig && this.formConfig.dictTypeModule,
          refresh: true,
          props: {
            placeholder: '请选择所属模块',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
            options: [],
          },
          dictCode: 'module_group',
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请选择所属模块',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'dictTypeCode',
          title: '类型编码',
          value: this.formConfig && this.formConfig.dictTypeCode,
          props: {
            placeholder: '请输入类型编码',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请输入类型编码',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'dictTypeName',
          title: '类型名称',
          value: this.formConfig && this.formConfig.dictTypeName,
          props: {
            placeholder: '请输入类型名称',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请输入类型名称',
            },
            {
              min: 2,
              max: 50,
              message: '类型名称范围2~50位字符,且不为空',
              trigger: 'blur',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'dictTypeDesc',
          title: '描述',
          value: this.formConfig && this.formConfig.dictTypeDesc,
          props: {
            placeholder: '请输入描述',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 12 },
          },
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/mdm/mdmdicttype/save';
        let params = formData;
        const { selectRow, code } = this.formConfig;

        if (code === 'edit') {
          url = '/mdm/mdmdicttype/update';
          params = { ...formData, id: selectRow.id };
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            // this.closeModal();
            // this.getList();
            this.resetFields();
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
    init() {
      const { selectRow } = this.formConfig;
      if (selectRow && selectRow.id) {
        request.get('/mdm/mdmdicttype/query', { id: selectRow.id }).then((res) => {
          if (res.success) {
            this.setValue(res.result);
          }
        });
      }
    },
  },
};
</script>
