import TablePage from './table';

export default {
  name: 'Dict',
  extends: TablePage,
  data() {
    return {};
  },
  methods: {},
  created() {},
};
