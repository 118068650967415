/*
 * @Author: chenlei
 * @Date: 2020-11-24 17:01:07
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-30 19:45:06
 * @Description:
 */
export default {
  formConfig: {
    titleWidth: 100,
    titleAlign: 'right',
    item: [
      {
        field: 'dictCode',
        type: 'input',
        title: '字典编码',
        search: true,
        span: 12,
      },
    ],
  },
  toolBarConfig: [
    {
      type: 'button',
      buttonType: null,
      icon: 'el-icon-plus',
      code: 'add-dict',
      name: '新增',
    },
    {
      type: 'button',
      buttonType: null,
      icon: 'el-icon-del',
      code: 'delete',
      name: '删除',
    },
  ],
  columns: [
    {
      fixed: 'left', // 固定位置在左边
      type: 'radio', // 单选
      title: null,
      width: '50',
      old: true,
    },
    {
      field: 'dictValue',
      title: '字典值',
      treeNode: true,
      old: true,
    },
    {
      field: 'dictCode',
      title: '字典编码',
      old: true,
    },
    // {
    //   field: 'path',
    //   title: '路径',
    //   old: true,
    // },
    {
      field: 'dictDesc',
      title: '字典描述',
      old: true,
    },
    {
      field: 'dictSort',
      title: '排序',
      old: true,
    },
  ],
  tableConfig: {
    border: true,
    treeConfig: {
      children: 'children',
    },
    // idKey: 'id', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
