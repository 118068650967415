/*
 * @Author: chenlei
 * @Date: 2020-11-25 13:39:41
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-25 13:52:47
 * @Description:
 */
import { formCreate } from '../../../../../../components/form';
import FieldForm from './field.form.vue';

formCreate.component('FieldForm', FieldForm);

export default FieldForm;
