/*
 * @Author: chenlei
 * @Date: 2020-11-25 13:39:41
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-25 13:52:47
 * @Description:
 */
import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [
        {
          type: 'input',
          field: 'dictCode',
          title: '字典编码',
          props: {
            placeholder: '请输入字典编码',
            // disabled: false,
            disabled: this.formConfig.code === 'edit-dict',
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请输入字典编码',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'dictValue',
          title: '字典值',
          props: {
            placeholder: '请输入字典值',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请输入字典值',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'dictDesc',
          title: '字典描述',
          props: {
            placeholder: '请输入字典描述',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          // validate: [
          //   {
          //     trigger: 'change',
          //     required: true,
          //     message: '请输入字典描述',
          //   },
          // ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'dictSort',
          title: '排序',
          props: {
            placeholder: '请输入排序',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请输入显示顺序',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
      ],
      extFieldS: [],
    };
  },
  mounted() {
    this.resetFields();
    this.getExtendField();
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();
      this.extFieldS.forEach((item) => {
        const isInput = !item.selectDictTypeCode;
        if (!isInput && item.selectSingleOnly !== '1') {
          formData[item.extField] = formData[item.extField].join(',');
        }
      });
      const {
        dictTypeCode, row, code, parentDictCode,
      } = this.formConfig;

      if (formData) {
        let url = '/mdm/mdmdictdata/save';
        let params = formData;

        params.dictTypeCode = dictTypeCode;

        if (code === 'edit-dict') {
          url = '/mdm/mdmdictdata/update';
          params = {
            ...formData, id: row.id, dictTypeCode, parentDictCode,
          };
        } else if (code === 'add-dict-child') {
          url = '/mdm/mdmdictdata/save';
          params = { ...formData, parentDictCode, dictTypeCode };
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            // this.closeModal();
            // this.getList();
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
    getExtendField() {
      const { dictTypeCode } = this.formConfig;
      request
        .post('/mdm/mdmdictattrconf/findAttrConfList', {
          dictTypeCode,
        })
        .then((res) => {
          if (res.success) {
            const rule = [];
            const data = res.result || [];

            data.forEach((item) => {
              this.extFieldS.push(item);
              const isInput = !item.selectDictTypeCode;
              const required = item.required !== '0';
              let tmp = null;
              if (isInput) {
                tmp = {
                  type: 'input',
                  field: item.extField,
                  title: item.fieldName,
                  value:
                    this.formConfig && this.formConfig[item.extField]
                      ? this.formConfig.row[item.extField]
                      : '',
                  props: {
                    placeholder: '请输入',
                    disabled: false,
                    clearable: true, // 是否显示清空按钮
                  },
                  validate: [
                    {
                      trigger: 'change',
                      required,
                      message: '请输入',
                    },
                  ],
                  col: {
                    md: { span: 12 },
                  },
                };
              } else {
                tmp = {
                  type: 'select',
                  field: item.extField,
                  title: item.fieldName,
                  value:
                    this.formConfig && this.formConfig[item.extField]
                      ? this.formConfig.row[item.extField]
                      : '',
                  props: {
                    placeholder: '请选择',
                    multiple: item.selectSingleOnly !== '1',
                    options: [],
                    loading: true,
                    disabled: false,
                    clearable: true, // 是否显示清空按钮
                  },
                  dictCode: item.selectDictTypeCode,
                  validate: [
                    {
                      trigger: 'blur',
                      required,
                      message: '请选择',
                    },
                  ],
                  col: {
                    md: { span: 12 },
                  },
                };
              }

              rule.push(tmp);
            });

            this.rule = this.rule.concat(rule);
            this.reload(this.rule);
            this.init();
          }
        });
    },
    init() {
      const { row } = this.formConfig;
      if (row.id) {
        request.get('/mdm/mdmdictdata/query', { id: row.id }).then((res) => {
          if (res.success) {
            const data = res.result;
            this.extFieldS.forEach((item) => {
              const isInput = !item.selectDictTypeCode;
              if (!isInput && item.selectSingleOnly !== '1') {
                data[item.extField] = data[item.extField] ? data[item.extField].split(',') : [];
              }
            });
            this.setValue(data);
          }
        });
      }
    },
  },
};
